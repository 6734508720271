export async function useDelayShow() {
    const showComponent = ref(false)

    await new Promise(resolve => {
        if (process.client) {
            setTimeout(resolve)
        } else {
            setImmediate(resolve)
        }
    })
    showComponent.value = true

    return {showComponent}
}
